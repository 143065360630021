<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:39:54
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-02 15:32:25
 * @Description: 
-->
<template>
  <div id="inventoryRecord">
     <search :addName="addName" @searchBut="searchBut" :seceltModel="seceltModel" @searchFormType="searchFormType" :searchSelectList="specifications" :searchListInfo="searchListInfo" :timeInterval="timeInterval" 
     @sectionChange="sectionChange"/>






    <div class="stockBox">
      <div class="stockItem" v-for="item in stockList" :key="item.id">
        <img :src="getUrl(item)" alt="" />
        <div class="stockInfo">
          <span>产品名称：{{ item.batchName }}</span>
          <span>批次名：{{ item.batchNum }}</span>
          <span>库存数量：{{ item.count }}</span>
 
          <!-- <span>备注：{{ item.remarks }}</span> -->
        </div>
      </div>
    </div>
    <page @changePage="eventPage" @changeSize="eventPage" class="pageStyle" :pagination="pagination" />
  </div>
</template>

<script>
    import search from '@/components/soureUnit/tableSearch.vue';
    import page from '@/components/page.vue';
    export default {
        components: {
            search,
            page
        },
        data() {
            return {
                addName: 'addName',
                searchListInfo: {
                    name: '产品名称',
                    type: '规格',
                },
                seceltModel: true,
                timeInterval: true,
                stockList: [],
                pagination: {
                    totalRow: 0,
                    page: 1,
                    size: 8,
                },
                startTime: '',
                endTime: '',
                searchName: '',
                specifications: [{
                    name: '全部',
                    id: '全部'
                }, {
                    name: '小麦',
                    id: '小麦'
                }, {
                    name: '水稻',
                    id: '水稻'
                }, {
                    name: '西红柿',
                    id: '西红柿'
                }, {
                    name: '茄子',
                    id: '茄子'
                }, {
                    name: '辣椒',
                    id: '辣椒'
                }, {
                    name: '白菜',
                    id: '白菜'
                }, {
                    name: '胡萝卜',
                    id: '胡萝卜'
                }, {
                    name: '南瓜',
                    id: '南瓜'
                }, {
                    name: '莴苣',
                    id: '莴苣'
                }, {
                    name: '菠菜',
                    id: '菠菜'
                }, {
                    name: '芝麻',
                    id: '芝麻'
                }, {
                    name: '橄榄',
                    id: '橄榄'
                }, {
                    name: '油菜',
                    id: '油菜'
                }, {
                    name: '韭菜',
                    id: '韭菜'
                }, {
                    name: '核桃',
                    id: '核桃'
                }, {
                    name: '苹果',
                    id: '苹果'
                }, {
                    name: '玉米',
                    id: '玉米'
                }, {
                    name: '樱桃',
                    id: '樱桃'
                }, {
                    name: '刀豆',
                    id: '刀豆'
                }, {
                    name: '香菜',
                    id: '香菜'
                }, ],
            };
        },
        mounted() {
            //console.log('mounted');
            //console.log(this.$attrs);
            this.requestStock();

        },
        methods: {
            sectionChange(times) {
                if (times) {
                    this.startTime = times[0];
                    this.endTime = times[1];
                } else {
                    this.startTime = '';
                    this.endTime = '';
                }

                this.requestStock();
            },
            getUrl(item) {
                if (item.img) {
                    if (typeof(item.img) == 'string') {
                        return item.img;
                    } else if (item.img.length > 0) {
                        return item.img[0].url;
                    }
                }

            },
            eventPage() {
                this.requestStock();
            },
            searchBut(val) {
                this.pagination.page = 1;
                this.pagination.size = 8;
                this.searchName = val.name;
                this.requestStock();
            },
            searchFormType(val) {
                this.searchName = val;
                this.requestStock();
            },
            requestStock() {
                let name = this.searchName;
                if (name == '全部') {
                    name = '';
                }
                this.$get('/inventoryrecords/getInventoryrecords', {
                    page: this.pagination.page,
                    limit: this.pagination.size,
                    name: name,
                    startTime: this.startTime,
                    endTime: this.endTime
                }).then((res) => {
                    if (res.data.state == 'success') {
                        let {
                            datas
                        } = res.data;
                        datas.forEach((element) => {
                            if (this.isJSON_test(element.img)) element.img = JSON.parse(element.img);
                        });
                        this.stockList = res.data.datas;
                        this.pagination.totalRow = res.data.count;
                    }
                });
            },

            isJSON_test(str) {
                if (typeof str == 'string') {
                    try {
                        var obj = JSON.parse(str);
                        //console.log(obj);
                        return true;
                    } catch (e) {
                        //console.log('error：' + str + '!!!' + e);
                        return false;
                    }
                }
            },
        },
    };
</script>
<style scoped lang="less">
    #inventoryRecord {
        background: #fff;
        padding: 2vh;
        box-sizing: border-box;
        height: 90vh;
    }
    
    .stockBox {
        display: flex;
        flex-wrap: wrap;
        margin: 2vh 0;
        max-height: 72vh;
    }
    
    .stockItem {
        width: 19vw;
        height: 17vh;
        border: 1px solid #ccc;
        display: flex;
        margin-top: 1vh;
        /* padding-top: 2vh; */
        box-sizing: border-box;
        justify-content: space-evenly;
        margin-right: 2vh;
        img {
            border-radius: 50%;
            width: 8vh;
            height: 8vh;
            margin-top: 4vh;
            border: 1px solid red;
        }
        .stockInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 1.5vh;
        }
    }
    
    #inventoryRecord /deep/ .storkBut {
        display: none;
    }
    
    // .pageStyle {
    //   position: absolute;
    //   bottom: 2vh;
    // }
    .stockInfo span {
        height: 4vh;
        line-height: 4vh;
        width: 10vw;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
</style>